<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="战报详情"
    width="800px"
    
  >
    <div>
      <el-form ref="form" :model="formData"   label-width="100px">

        <el-form-item label="职位" prop="title" >  
          {{ formData.title }}
        </el-form-item>
        <el-form-item label="昵称	" prop="nickname" >  
          {{ formData.nickname }}
        </el-form-item>

        <!--
        <el-form-item label="头像	" prop="avatar" >  
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.user_avatar"
            :fit="fit"></el-image>
        </el-form-item>
-->
        <el-form-item label="提交时间	" prop="created_at" >  
          {{ formData.created_at }}
        </el-form-item>
       

        <el-form-item label="类型	" prop="type" >  
          <!--1日报2周报3月报
          -->
          <span v-if="formData.type==1">日报</span>
          <span v-if="formData.type==2">周报</span>
          <span v-if="formData.type==3">月报</span>
          
        </el-form-item>

    
        
        
        <el-row  v-for="(item, index) in formData.group_content2" :key="index">
            <el-form-item  :label="item.title"  >

               {{ item.value }}
            </el-form-item>
        
        </el-row>

        <!--        1待审核2审核通过3审核拒绝-->
        <el-form-item label="评价内容" required>
          <el-input type="textarea" v-model="auditFormData.content"></el-input>
        </el-form-item>
        	
    
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>
//import { reportInfo,reportAssess } from "@/api/director/workmaster/index";
import { jobList,projectList,leaveList,leaveInfo,clockDayList,reportList,reportInfo,reportAddCommon} from "@/api/application/index";

export default {

  data() {
    return {
      visible: false,
      report_id:"",
      formData: {
        report_id:"",
        alias_name:"",//用户名
        user_avatar:"",
        created_at: "",//提交时间
        title: "",//职位名

        type: "",//1病假2婚假3事假4丧假5出差6加班7办公费8差旅费


        mark:"",//事由
        group_content:[],
        group_content2:[],
        status:"",
        hasDirector:[],
        hasCopy:[]
      
      },

      auditFormData:{
        content:null,
        report_id:null
      }
       
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.visible = false;
      this.$refs["form"].resetFields();

    },


    init(report_id) {
      this.formData.report_id=report_id;
      this.report_id=report_id;
      this.initData();
      this.visible = true;
    },

    initData(){
      var that=this;
      reportInfo({"report_id":that.report_id}).then((response) => {
        console.log(response.data.group_content);
   

        if(response.code==200){
          that.formData=response.data

          let groups=[];
          var group_content=response.data.group_content;
          let keys = Object.keys(group_content)
          for (let i =0; i < keys.length; i++) {
            
              groups.push({
                title:keys[i],
                value:group_content[keys[i]],
              })
          }
          that.formData.group_content2=groups;

        }else{
          this.$message({
            message: response.msg?response.msg:"获取信息失败",
          });
        }
      });
    },

   
    
    
    onSubmit(){
      var that=this;
      that.auditFormData.report_id=that.report_id
      reportAddCommon(that.auditFormData).then((response) => {
        console.log(response);
        if(response.code==200){
          this.$message({
              message: "提交成功",
              type: 'success'

          });
          that.$emit('refresh');
          that.visible = false;
        
        }else{
          this.$message({
            message: response.msg?response.msg:"提交失败",
          });
        }
      });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
